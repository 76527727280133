<template>
  <div>
    <van-nav-bar title="扫描推动" left-text="返回" left-arrow @click-left="back()" />
  </div>
  <div class="d_form">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <div class="flex scan">
          <div style="width: 88%;"><van-field v-model="dm" label="地码" /></div>
          <div><van-icon name="scan" size="22" /></div>
        </div>
        <van-field v-model="status1" label="库位状态" readonly />
        <div class="flex scan">
          <div style="width: 88%;"><van-field v-model="dm" label="工装号" /></div>
          <div><van-icon name="scan" size="22" /></div>
        </div>
        <van-field v-model="gg" label="工装类型" readonly />
        <div style="height: 1px; border-top: 1px solid black"></div>
        <div class="row_box">
          <div style="width: 180px">触发类型：库位状态</div>
          <div>触发方式：拉动</div>
        </div>
        <div class="row_box">
          <div style="width: 180px">起始仓库：接驳区</div>
          <div>目标仓库：成品区</div>
        </div>
        <div class="flex scan">
          <div style="width: 88%;">
            <van-field
                v-model="materiel_v"
                is-link
                readonly
                name="picker"
                label="选择器"
                placeholder="点击选择物料"
                @click="materielPicker = true"
            />
            <van-popup v-model:show="materielPicker" position="bottom">
              <div class="flex scan">
                <div style="width: 87%;"><van-field v-model="materiel_v" label="物料名称" /></div>
                <div><van-button type="primary" size="mini">确认</van-button></div>
              </div>
              <van-picker
                  :columns="columns"
                  @confirm="onConfirm"
                  @cancel="materielPicker = false"
              />
            </van-popup>
          </div>
          <div><van-icon name="scan" size="22" /></div>
        </div>
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item title="物料列表" name="1">
            <div class="table-container">
              <table class="wide-table">
                <tr>
                  <th>序号</th>
                  <th>物料名称</th>
                  <th>物料编码</th>
                  <th>数量</th>
                  <th>物料类型</th>
                  <th>状态</th>
                  <th>批次</th>
                </tr>
                <tr v-for="user in list2" :key="user.id">
                  <td>{{ user.id }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.code }}</td>
                  <td>{{ user.number }}</td>
                  <td>{{ user.type }}</td>
                  <td>{{ user.status }}</td>
                  <td>{{ user.lot }}</td>
                </tr>
              </table>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div style="margin: 16px;">
          <van-button block type="primary" size="small" native-type="submit" :disabled="flag" @click="onSubmit">
            确定
          </van-button>
        </div>
        <van-popup v-model:show="statusPicker" position="bottom">
          <van-picker :columns="columns" :columns-field-names="customFieldName" @confirm="statusConfirm" @cancel="statusPicker = false" />
        </van-popup>
        <van-tabs v-if="flag" v-model:active="active">
          <van-tab title="扫出">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="sc" label="直接扫出" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="cf" label="拆分看板扫出" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>拆分</th>
                    <th>剩余</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="user in list3" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.chai }}</td>
                    <td>{{ user.sheng }}</td>
                    <td><van-button type="primary" size="mini">拆分</van-button><van-button type="primary" size="mini">打印</van-button></td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
          <van-tab title="移库">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="yk" label="直接移库" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="yr" label="移入位置" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="xx" label="拆分看板移库" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="xx" label="移入位置" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <van-cell v-for="item in listC" :key="item.a">
                <div class="flex kan">
                  物料名称：{{ item.a }}---
                  物料编码 {{ item.code }}
                  拆分：{{ item.b }}---
                  剩余：{{item.c}}
                  <van-button type="primary" size="small">拆分</van-button>
                </div>
              </van-cell>
            </div>
          </van-tab>
          <van-tab title="扫入">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="sr" label="直接扫入" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
        </van-tabs>
        <div v-if="flag === true" style="margin: 16px;">
          <van-button block type="primary" size="small" native-type="submit">
            确定
          </van-button>
        </div>
        <div style="height: 40px"></div>
      </van-cell-group>

    </van-form>
  </div>

</template>
<script setup>
import { useRouter } from "vue-router"
import { ref } from 'vue'
import { onBeforeUpdate } from 'vue'
const router = useRouter()
const name = ref('推动A')
const type = ref('扫描')
const active = ref(1)
const status = ref(1)
const statusName = ref('推动')
const end = ref('成品区')
const start = ref('接驳区')
const status1 = ref('空')
const jig = ref('工装号')
const jig_type = ref('类型1')
const flag = ref(false)
const jigType = ref('')
const materiel_v = ref('')
const materielPicker = ref(false)
const activeName = ref(1);
const checkboxRefs = ref([]);
const toggle = (index) => {
  checkboxRefs.value[index].toggle();
};
const title1= ref('订单号 -- 状态（未完成）')
onBeforeUpdate(() => {
  checkboxRefs.value = [];
});
const customFieldName = {
  text: 'value',
  id: 'id'
}
const columns = [
  { id: 1, value: '推动' },
  { id: 2, value: '拉动' },
]
const statusPicker = ref(false)
const list = ref([])
const list2= ref([
  { id: 1,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    providerCode: '供应商A',
    Ekanban: 'kanban',
    lot: 1
  },
  {  id: 2,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    providerCode: '供应商A',
    Ekanban: 'kanban',
    lot: 1
  }
])
const list1= ref([
  { name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    lot: 1
  },
  { name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    lot: 1
  }
])
const list3= ref([
  { id: 1,
    name: '后视镜',
    code: 'hsj-50',
    number: '20',
    chai: '10',
    sheng: '10',
    lot: 1
  },
  {  id: 2,
    name: '后视镜',
    code: 'hsj-50',
    number: '20',
    chai: '10',
    sheng: '10',
    lot: 1
  }
])
const listA = ref([
  '1', '2', 3, 4
])
function statusConfirm(value) {
  status.value = value.id
  statusName.value = value.value
  statusPicker.value = false
}
const listC = ref([
  {
    a: '看板',
    b: '拆分',
    c: '剩余'
  }
])

const listB = ref([
  '任务1', '任务2'
])
const finished = ref(false)
function back() {
  router.go(-1);
}
function onSubmit() {
  flag.value = !flag.value
  title1.value = '订单号--状态（完成）'
}
</script>
<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.list2 .van-cell__title span{
  font-size: 0.3rem;
}
.scan .van-icon-arrow {
  margin-top: 5px;
}
.fz div{
  width: 50%;
  display: flex;
  font-size: 0.3rem;
}
</style>
